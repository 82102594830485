import clsx from "clsx";
import Image from "next/image";
import Button from "@/components/Button";
import { useRef, useEffect, useState, useMemo } from "react";
import Marquee from "react-fast-marquee";
import posthog from "posthog-js";
import dynamic from "next/dynamic";

const MealPlansPremiumHero = ({
  heading,
  subheading,
  features,
  imageCarousel,
  staticImage,
  target,
  footerTerms,
  offerText,
  logo,
  minHeight,
}) => {
  const MealPlansImageGrid = dynamic(() => import("./MealPlansImageGrid"), {
    ssr: false,
  });
  const showLimitedTimeOfferMessaging = true; // TODO - JAN SALE 2025 - Remove logic for this after sale is over

  const offerTextContainerRef = useRef(null);
  const offerTextRef = useRef(null);
  const [offerTextDimensions, setOfferTextDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleSetOfferTextDimensions = () => {
    setOfferTextDimensions({
      width: offerTextRef.current?.scrollWidth,
      height: offerTextRef.current?.scrollHeight,
    });
  };

  useEffect(() => {
    handleSetOfferTextDimensions();
  }, [showLimitedTimeOfferMessaging]);

  useEffect(() => {
    window.addEventListener("resize", handleSetOfferTextDimensions);

    return () => {
      window.removeEventListener("resize", handleSetOfferTextDimensions);
    };
  }, []);

  const numOfTextItems = useMemo(() => {
    if (offerTextDimensions.width && offerTextDimensions.height) {
      const offerTextContainerHeight =
        offerTextContainerRef.current.clientHeight;

      const numOfItems = Math.ceil(
        offerTextContainerHeight / offerTextDimensions.height
      );

      return numOfItems;
    } else return 1;
  }, [offerTextDimensions]);

  return (
    <div
      className={clsx(
        "flex flex-col overflow-hidden bg-acid-100 font-spatial text-gray-900 lg:flex-row"
      )}
      style={{
        minHeight: minHeight ?? "calc(100vh - var(--header-height))",
      }}
    >
      <div
        className={clsx(
          "mx-auto w-full flex-1 px-4 py-10 text-center xs:max-w-[555px] sm:px-10 lg:mx-0 lg:w-[50%] lg:max-w-[550px] lg:py-16 lg:text-left xl:max-w-[600px] 2xl:max-w-[700px] 2xl:py-32"
        )}
      >
        <div className={clsx("flex h-full w-full flex-col justify-between")}>
          <div className="flex flex-col items-center gap-6 lg:items-start lg:gap-7 xl:gap-8 2xl:gap-10">
            {logo && logo.length > 0 && (
              <img
                src={logo[0].url}
                alt={logo[0].title}
                className="h-4 lg:h-5 xl:h-7"
              />
            )}
            <h1
              className={
                "text-[26px] font-bold leading-none sm:text-[28px] lg:text-[48px] lg:leading-[45px] xl:text-[52px] xl:leading-[46px] 2xl:text-[65px] 2xl:leading-[58px]"
              }
            >
              {heading}
            </h1>
            <div className="font-sans text-sm font-medium text-gray-900 xl:text-xl xl:font-extralight">
              From just £2.92 per month*. Cancel anytime.
            </div>
            <ul className="flex w-[350px] flex-col gap-3 rounded-2xl border border-gray-900 p-4 lg:w-full lg:border-none lg:p-0">
              {features.split("\n").map((feature) => (
                <li
                  key={feature}
                  className="flex items-center gap-[5px] font-body text-xs font-medium leading-snug lg:text-[15px] lg:font-normal"
                >
                  <Image
                    alt="check"
                    src="/images/check.svg"
                    width="20"
                    height="20"
                    priority
                  />
                  {feature}
                </li>
              ))}
            </ul>
            <div className="flex w-full justify-center lg:justify-start">
              <Button
                className="min-w-[187px]"
                theme="light"
                href={target.url}
                onClick={() => {
                  posthog.capture("premium_hero_button_click");
                }}
              >
                {target.text}
              </Button>
            </div>
          </div>
          <div className="mt-6 font-sans text-xs font-normal leading-none text-gray-800 lg:mt-7 xl:mt-8 2xl:mt-10">
            *Price is based on standard annual plan at £35 per year
          </div>
        </div>
      </div>
      <div
        className={clsx("w-full overflow-hidden lg:flex lg:flex-1 lg:pt-0", {
          "bg-teal": offerText && showLimitedTimeOfferMessaging,
        })}
      >
        {!!offerText && showLimitedTimeOfferMessaging && (
          <div
            className="relative h-full py-[13px] text-[22px] font-extralight leading-[1.5] text-acid"
            ref={offerTextContainerRef}
          >
            <div className="block lg:hidden">
              <Marquee autoFill speed={30} style={{ transform: "scaleX(-1)" }}>
                <div style={{ transform: "scaleX(-1)" }}>
                  {offerText}&nbsp;.&nbsp;
                </div>
              </Marquee>
            </div>
            <div
              className={clsx(
                "absolute hidden rotate-180 flex-row-reverse whitespace-nowrap px-2 text-[32px] font-extralight leading-[1.5] text-acid [writing-mode:vertical-lr] lg:flex"
              )}
            >
              <div className="flex animate-marquee-vertical-up">
                <div ref={offerTextRef} className="h-0 overflow-hidden">
                  {offerText}&nbsp;.&nbsp;
                </div>

                {Array(
                  !numOfTextItems || numOfTextItems === Infinity
                    ? 1
                    : numOfTextItems
                )
                  .fill("")
                  .map((_, i) => (
                    <div key={"marquee" + i}>{offerText}&nbsp;.&nbsp;</div>
                  ))}
              </div>
              <div className="flex animate-marquee-vertical-up">
                {Array(
                  !numOfTextItems || numOfTextItems === Infinity
                    ? 1
                    : numOfTextItems
                )
                  .fill("")
                  .map((_, i) => (
                    <div key={"marquee2" + i}>{offerText}&nbsp;.&nbsp;</div>
                  ))}
              </div>
            </div>
          </div>
        )}
        <div className="h-full w-full">
          <MealPlansImageGrid />
        </div>
      </div>
    </div>
  );
};

export default MealPlansPremiumHero;
