import DraggableScrollArea from "@/components/DraggableScrollArea";
import MobLogotype from "@/components/MobLogotype";
import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/legacy/image";
import Link from "next/link";

const MobPlusPromotion = ({ blocks, heading, introduction }) => {
  return (
    <div className={clsx("bg-cobalt py-10 text-white xl:py-12")}>
      <div className={clsx("")}>
        <div
          className={clsx(
            "grid grid-cols-12 gap-x-8 border-b border-b-white/20 pb-10"
          )}
        >
          <div className={clsx("col-span-12 lg:col-span-6")}>
            <div
              className={clsx(
                "flex flex-col space-y-4 px-6 max-lg:items-center lg:px-12"
              )}
            >
              <div className={clsx("heading-xl--spatial")}>{heading}</div>
            </div>
          </div>
          <div
            className={clsx(
              "col-span-12 flex justify-center lg:col-span-6 lg:justify-end"
            )}
          >
            <div className={clsx("px-6 pt-6 lg:px-12 lg:pt-0")}>
              <Link
                href="/premium"
                className={clsx(
                  "flex w-full rounded-[100%] border border-white px-12 py-6 font-spatial text-lg font-thin focus:border-white active:scale-95 lg:ml-auto lg:text-xl"
                )}
              >
                Unlock the offer
              </Link>
            </div>
          </div>
        </div>
        <div className={clsx("grid grid-cols-12 gap-x-8")}>
          <div className={clsx("col-span-12 pt-6 lg:col-span-6 lg:pt-10")}>
            <div
              className={clsx("body-text px-6 lg:px-12")}
              dangerouslySetInnerHTML={{ __html: introduction }}
            ></div>
          </div>
          <div className={clsx("col-span-12 lg:col-span-6 ")}>
            <div
              className={clsx(
                "px-6 pt-6 font-spatial font-thin lg:px-12 lg:pt-10 lg:text-right"
              )}
            >
              <div>5-Day Free Trial</div>
              <div>Then just £4.99 a month or £35.00 a year</div>
            </div>
          </div>

          <div className={clsx("col-span-12 pt-12")}>
            <_BlockCarousel blocks={blocks} />
          </div>
        </div>
      </div>
    </div>
  );
};

const _BlockCarousel = ({ blocks }) => {
  return (
    <>
      <DraggableScrollArea>
        <div className={clsx("flex space-x-6 px-6 lg:px-12")}>
          {blocks.map((block) => {
            const image = block.image?.[0];

            return (
              <div key={block.id} className={clsx("w-72")}>
                <div>
                  <div
                    className={clsx(
                      "relative isolate aspect-16/11 overflow-hidden rounded-2xl bg-white/10"
                    )}
                  >
                    {!!image && (
                      <Image
                        src={image.url}
                        layout="fill"
                        objectFit="cover"
                        objectPosition="center"
                        alt={image.title}
                        draggable={false}
                        loader={cloudflareLoader}
                      />
                    )}
                  </div>
                  <div className={clsx("flex items-start space-x-3 pt-3")}>
                    <div
                      className={clsx(
                        "h-4 w-4 shrink-0 translate-y-1.5 rounded-full border border-white"
                      )}
                    ></div>
                    <div className={clsx("font-spatial font-thin lg:text-lg")}>
                      {block.heading}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </DraggableScrollArea>
    </>
  );
};

export default MobPlusPromotion;
